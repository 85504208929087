.p-topContents {
    background-color: rgba($BLK, 1);
    padding: 80px 0 0;
    @include mq($from: mobile, $until: tablet) {
        padding: 30px 0 0;
    }
    &__Head {
        background: rgba($BLK, 1) url("../img/bg.png") no-repeat bottom center / 320px 580px;
        width: 320px;
        padding-bottom: 640px;
        margin: 0 auto;
        @include mq($from: mobile, $until: tablet) {
            background: rgba($BLK, 1) url("../img/bg.png") no-repeat bottom center / 160px 290px;
            width: 100%;
            padding-bottom: 320px;
        }
        h1 {
            width: width("logo.png", 2);
            height: height("logo.png", 2);
            margin: 0 auto;
            img {
                width: auto;
                height: height("logo.png", 2);
            }
        }
    }
    &__Innr {
        width: 320px;
        margin: 0 auto;
        color: rgba($WHT, 1);
        @include font-size(16);
        @include mq($from: mobile, $until: tablet) {
            width: 100%;
            padding: 0 20px;
            @include font-size(14);
        }
        h3 {
            text-align: left;
            @extend %f-en_b;
            @include mq($from: mobile, $until: tablet) {
                @include font-size(16);
            }
        }
        -address {
            @extend %f-jp_b;
        }
        -tel {
            @extend %f-jp_b;
        }
        -way {
            @extend %f-jp_b;
        }
        -time {
            @extend %f-jp_b;
        }
    }
    &__Tweet {
        width: 100%;
        min-width: 280px;
        max-width: 375px;
        margin: 40px auto 0;
        text-align: center;

        @include mq($from: mobile, $until: tablet) {
            margin-top: 30px;
            padding: 0 20px;
        }
    }
    &__Map {
        width: 100%;
        margin-top: 80px;
        @include mq($from: mobile, $until: tablet) {
            margin-top: 40px;
        }
        iframe {
            width: 100%;
            height: 400px;
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            -o-filter: grayscale(100%);
            filter: grayscale(100%);
            @include mq($from: mobile, $until: tablet) {
                height: 240px;
            }
        }
    }
}
