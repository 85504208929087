.l-wrapper {
    @include mq($from: mobile, $until: tablet) {
		overflow: hidden;
    }
}
.l-contents {
	position: relative;
}

.l-footer{
	padding: 10px 0;
    @include mq($from: mobile, $until: tablet) {
	    padding: 6px 0;	
    }
	&__Innr{
		&-copyright{
			text-align: center;
			@include font-size(16);
			@extend %f-en_b;
			color: rgba($WHT, 1);
		}
	}
}