.l-wrapper {
	width: 100%;

    @include mq($from: mobile, $until: tablet) {
		overflow: hidden;
    }
}
.l-contents {
	position: relative;
}
