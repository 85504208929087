@charset "utf-8";

@mixin cf {
  zoom: 1;
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.cf {
  zoom: 1;
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin font-size ($sizeValue: 16) {
  $sizeRem: ($sizeValue / 10) - ((($sizeValue /10)*37.5)/100);
  font-size: $sizeValue + px;
  font-size: $sizeRem + rem;
}
@mixin fontsize ($sizeValue: 16) {
  $sizeRem: ($sizeValue / 10) - ((($sizeValue /10)*37.5)/100);
  font-size: $sizeValue + px;
  font-size: $sizeRem + rem;
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -o-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

/*
 * Mixin for placeholder
 * @include placeholderColor(#000000);
 */

@mixin placeholderColor($color) {
  &:placeholder-shown {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

/*
 * Mixin for selection
 * @include selectionColor(#000000);
 */

@mixin selectionColor($color) {
  &::-moz-selection {
    color: $color;
  }
  &::selection {
    color: $color;
    opacity: $color;
  }
}

//参考　https://moneyforward.com/engineers_blog/2015/03/10/sass_css_customize/
@mixin custom-input($size, $type) {
  // 見た目は画像を表示するので、input要素は非表示にする
  display: none; // 隣接セレクタを使い、inputの次にあるlabelに見た目を設定する
  +label {
    position: relative;
    display: inline-block; // 【任意】カスタマイズした要素と文字間隔を空ける。
    padding-left: $size + 6px;
    min-height: $size;
    line-height: $size;
    cursor: pointer;
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: $size;
      height: $size; // contentに表示したい画像を指定
      content: url("../img/#{$type}_off.svg");
    }
  } // :checkedの擬似クラスでinput要素のチェック状態を取得。labelは接続セレクタを使い、チェック状態用の画像を指定する
  &:checked+label::before {
    content: url("../img/#{$type}_on.svg"); // background-color: $LightShade;
  } // 【任意】input要素をdisabledにする事がある場合は、disabled用のスタイルを指定
  &:disabled+label {
    opacity: .1;
    cursor: not-allowed;
  }
}

//参考　https://moneyforward.com/engineers_blog/2015/03/10/sass_css_customize/
@mixin custom-input__small($size,
$type) {
  // 見た目は画像を表示するので、input要素は非表示にする
  position: relative;
  display: none; // 隣接セレクタを使い、inputの次にあるlabelに見た目を設定する
  +label {
    position: relative;
    display: block; // 【任意】カスタマイズした要素と文字間隔を空ける。
    padding-left: $size + 5px;
    margin-right: $size;
    min-height: $size;
    line-height: $size;
    cursor: pointer;
    &::before {
      position: absolute;
      left: -4px;
      top: 0px;
      display: inline-block;
      display: block;
      width: $size;
      height: $size;
      margin: 0 auto 8px; // contentに表示したい画像を指定
      content: url("../img/#{$type}_off.svg");
    }
  } // :checkedの擬似クラスでinput要素のチェック状態を取得。labelは接続セレクタを使い、チェック状態用の画像を指定する
  &:checked+label::before {
    content: url("../img/#{$type}_on.svg"); // background-color: $LightShade;
  } // 【任意】input要素をdisabledにする事がある場合は、disabled用のスタイルを指定
  &:disabled+label {
    opacity: .1;
    cursor: not-allowed;
  }
}
