@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700');
@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700');

/* Yu Gothic for Windows */
@font-face {
    font-family: MyYuGothic;
    font-weight: normal;

    src: local(YuGothic-Medium),
    local("Yu Gothic Medium"); /* for Chrome */
}
@font-face {
    font-family: MyYuGothic;
    font-weight: bold;

    src: local(YuGothic-Bold),
    local("Yu Gothic"); /* for Chrome */
}

body {
    visibility: hidden;
    font-family: sans-serif;
    /*visibility: visible;*/

    font-feature-settings: "palt" 1;
}
body[data-os^="Windows"] {
    visibility: visible;
    font-family: "Noto Sans CJK JP", Meiryo, sans-serif;

    font-feature-settings: "pkna" 1;
    .ie & {
        font-feature-settings: initial;
    }
}
body[data-os="Windows 10"] {
    visibility: visible;
    font-family: "Noto Sans CJK JP", MyYuGothic, Meiryo, sans-serif;

    font-feature-settings: "pkna" 1;
    .ie & {
        font-feature-settings: initial;
    }
}
body[data-os^="Mac OS"] {
    visibility: visible;
    font-family: "Noto Sans Japanese", YuGothic, sans-serif;

    font-feature-settings: "palt" 1;
    .safari & {
        font-feature-settings: "pkna" 1;
    }
}
body[data-os^="iOS"] {
    visibility: visible;
    font-family: sans-serif;

    font-feature-settings: "palt" 1;
}
body[data-os^="Android"] {
    visibility: visible;
    font-family: Roboto, sans-serif;

    font-feature-settings: "palt" 1;
}
body[data-os^="Android 4"],
body[data-os^="Android 5"] {
    visibility: visible;
    font-family: Roboto, MotoyaLCedar, sans-serif;

    font-feature-settings: "palt" 1;
}
body[data-os^="undefined"] {
    visibility: visible;
    font-family: sans-serif;

    font-feature-settings: "palt" 1;
}

// Noto Serif Japanese
.f-en_m {
  font-family: 'Libre Baskerville', serif !important;
  font-weight: 400 !important;
}
%f-en_m {
  font-family: 'Libre Baskerville', serif !important;
  font-weight: 400 !important;
}
.f-en_b {
  font-family: 'Libre Baskerville', serif !important;
  font-weight: 700 !important;
}
%f-en_b {
  font-family: 'Libre Baskerville', serif !important;
  font-weight: 700 !important;
}
// Noto Sans Japanese
.f-jp_m {
  font-family: 'Noto Sans', sans-serif !important;
  font-weight: 400 !important;
}
%f-jp_m {
  font-family: 'Noto Sans', sans-serif !important;
  font-weight: 400 !important;
}
.f-jp_b {
  font-family: 'Noto Sans', sans-serif !important;
  font-weight: 700 !important;
}
%f-jp_b {
  font-family: 'Noto Sans', sans-serif !important;
  font-weight: 700 !important;
}


