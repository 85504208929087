@charset "utf-8";

/*MONO*/
$WHT: #FFF;
$GRY_LIGHTEST: #FBF8F5;
$GRY_LIGHTER: #E1DEDC;
$GRY_LIGHT: #787878;
$GRY: #4A4A4A;
$GRY_DARKER: #212121;
$GRY_DARKEST: #121212;
$NVY: #11141d;
$BLK: #000000;


/*COLOR*/

$ORG:#F3981C;
$ORG_L:#FB4C20;
$YLW_L:#E8FC11;
$YLW:#EFE447;
$YLW_D:#B4EC51;
$RED:#830000;
$RED_L:#B81413;
$PNK_L:#FFCCF7;
$PNK:#F21D64;
$PRP_L:#F7B3EE;
$PRP:#D943F1;
$BLU:#3023AE;
$CIN:#24FFFF;
$CIN_D:#1DCCCC;
$CIN_DD:#23AEA9;

$CIN_1:#22a7b0;
$CIN_2:#6fe7ff;

$Kids:#f3981c;
$CM:#830000;
$TV:#fb4c20;
$Artist:#b4c30b;
$Stage:#2db8b8;
$Movie:#cf40e1;
$ActorRadio:#de87d5;
$Excercise:#f33e7a;
$BookOther:#4a90e2;

$HOV: $RED;

%grade-blog {
    background-image: linear-gradient(to bottom, rgba($CIN_1, 1) 0%, rgba($CIN_2, 1) 100%);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
}
%grade-ikeda {
    background-image: linear-gradient(to bottom, rgba($PNK, 1) 15%, rgba($ORG, 1) 100%);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
}
%grade-saiki {
    background-image: linear-gradient(to bottom, rgba($PNK_L, 1) 15%, rgba($CIN, 1) 100%);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
}
%grade-adachi {
    background-image: linear-gradient(to bottom, rgba($ORG, 1) 15%, rgba($PNK_L, 1) 100%);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
}
%grade-ikebun {
    background-image: linear-gradient(to bottom, rgba($CIN, 1) 15%, rgba($YLW, 1) 100%);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
}
%grade-kato {
    background-image: linear-gradient(to bottom, rgba($PNK_L, 1) 15%, rgba($PRP, 1) 100%);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
}
%grade-ikeda50 {
    background-image: linear-gradient(to bottom, rgba($PNK, .6) 15%, rgba($ORG, .6) 100%);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
}
%grade-saiki50 {
    background-image: linear-gradient(to bottom, rgba($PNK_L, .6) 15%, rgba($CIN, .6) 100%);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
}
%grade-adachi50 {
    background-image: linear-gradient(to bottom, rgba($ORG, .6) 15%, rgba($PNK_L, .6) 100%);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
}
%grade-ikebun50 {
    background-image: linear-gradient(to bottom, rgba($CIN, .6) 15%, rgba($YLW, .6) 100%);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
}
%grade-kato50 {
    background-image: linear-gradient(to bottom, rgba($PNK_L, .6) 15%, rgba($PRP, .6) 100%);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
}




html,
body {
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    background-color: rgba($BLK, 1);
}
a.is-tLink {
    color: rgba($BLK, 1);
    text-decoration: underline;
    letter-spacing: 3px;
    &:hover {
        color: rgba($GRY, 1);
        text-decoration: none;
    }
}

::-moz-selection {
    color: $CIN;
    background: $YLW_L;
}

::selection {
    color: $CIN;
    background: $YLW_L;
}


br.sp {
    display: none;
    @include mq($from: mobile, $until: tablet) {
        display: block;
    }
}

br.pc {
    display: block;
    @include mq($from: mobile, $until: tablet) {
        display: none;
    }
}
